"use client"

import React, { ReactNode } from "react"
import createCache, { EmotionCache } from "@emotion/cache"

import { CacheProvider as DefaultCacheProvider } from "@emotion/react"
import { useServerInsertedHTML } from "next/navigation"

interface NextAppDirEmotionCacheProviderProps {
  options?: {
    key: string
  }
  CacheProvider?: React.ComponentType<{ value: EmotionCache }>
  children: ReactNode
}

export default function NextAppDirEmotionCacheProvider(
  props: NextAppDirEmotionCacheProviderProps
): React.JSX.Element {
  const { options, CacheProvider: ProvidedCacheProvider, children } = props

  const [registry] = React.useState(() => {
    const cache = createCache(options as any)
    cache.compat = true
    const prevInsert = cache.insert
    let inserted: any[] = []
    cache.insert = (...args) => {
      const [selector, serialized] = args
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push({
          name: serialized.name,
          isGlobal: !selector,
        })
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache, flush }
  })

  useServerInsertedHTML(() => {
    const inserted = registry.flush()
    if (inserted.length === 0) {
      return null
    }
    let styles = ""
    let dataEmotionAttribute = registry.cache.key

    const globals: any[] = []

    inserted.forEach(({ name, isGlobal }) => {
      const style = registry.cache.inserted[name]

      if (typeof style !== "boolean") {
        if (isGlobal) {
          globals.push({ name, style })
        } else {
          styles += style
          dataEmotionAttribute += ` ${name}`
        }
      }
    })

    return (
      <>
        {globals.map(({ name, style }) => (
          <style
            key={name}
            data-emotion={`${registry.cache.key}-global ${name}`}
            dangerouslySetInnerHTML={{ __html: style }}
          />
        ))}
        {styles && (
          <style
            data-emotion={dataEmotionAttribute}
            dangerouslySetInnerHTML={{ __html: styles }}
          />
        )}
      </>
    )
  })

  const CacheProvider = ProvidedCacheProvider || DefaultCacheProvider

  return <CacheProvider value={registry.cache}>{children}</CacheProvider>
}
