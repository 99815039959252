export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
}

export const GRID_COLOR = "#54575B"
export const TICKER_COLOR = "#A3A5A9"
