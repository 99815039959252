import * as React from "react"

import MuiAlert, { AlertProps } from "@mui/material/Alert"

import Snackbar from "@mui/material/Snackbar"
import Stack from "@mui/material/Stack"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  }
)

interface MessageSnackbarType {
  message: string
  isOpen: boolean
  severity: "error" | "info" | "success" | "warning"
  handleClose: () => void
}

export default function MessageSnackbar({
  message,
  severity,
  isOpen,
  handleClose,
}: MessageSnackbarType) {
  return (
    <Stack spacing={2}>
      <Snackbar
        open={isOpen}
        autoHideDuration={null}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
