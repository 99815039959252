import '~/styles/global.css'
import 'katex/dist/katex.min.css'

import type { AppProps } from 'next/app'
import { IBM_Plex_Mono, Roboto } from 'next/font/google'
import { lazy } from 'react'
import ThemeRegistry from '~/components/theme-registry/theme-registry'
import 'easymde/dist/easymde.min.css'
import ReactQueryProvider from '~/providers/react-query-provider'
import { UpdateQueryParamsContextProvider } from '~/contexts/update-query-params-provider'
import { SnackbarProvider } from '~/contexts/message-snack-bar'
import { LicenseInfo } from '@mui/x-data-grid-premium'
import Layout from './components/layout'

LicenseInfo.setLicenseKey(
  '2b0e98f05f27eaa3f7d876dd2333eefbTz03ODYzOCxFPTE3MzE0NzY1MzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
)

export interface SharedPageProps {
  draftMode: boolean
  token: string
}

const PreviewProvider = lazy(() => import('~/components/PreviewProvider'))

const mono = IBM_Plex_Mono({
  variable: '--font-family-mono',
  subsets: ['latin'],
  weight: ['500', '700'],
})

const sans = Roboto({
  subsets: ['latin'],
  display: 'swap',
  weight: '400',
  preload: true,
})

const serif = Roboto({
  subsets: ['latin'],
  display: 'swap',
  weight: '400',
  preload: true,
})

export default function App({
  Component,
  pageProps,
}: AppProps<SharedPageProps>) {
  const { draftMode, token } = pageProps
  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-family-sans: ${sans.style.fontFamily};
            --font-family-serif: ${serif.style.fontFamily};
            --font-family-mono: ${mono.style.fontFamily};
          }
        `}
      </style>
      {draftMode ? (
        <PreviewProvider token={token}>
          <ThemeRegistry>
            <ReactQueryProvider>
              <SnackbarProvider>
                <UpdateQueryParamsContextProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </UpdateQueryParamsContextProvider>
              </SnackbarProvider>
            </ReactQueryProvider>
          </ThemeRegistry>
        </PreviewProvider>
      ) : (
        <>
          <ThemeRegistry>
            <ReactQueryProvider>
              <SnackbarProvider>
                <UpdateQueryParamsContextProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </UpdateQueryParamsContextProvider>
              </SnackbarProvider>
            </ReactQueryProvider>
          </ThemeRegistry>
        </>
      )}
    </>
  )
}
