"use client"

import React, { createContext, useContext, useState } from "react"

import MessageSnackbar from "@/components/global/message-snackbar"

type Severity = "success" | "error" | "info" | "warning"

const SnackbarContext = createContext<
  | {
      showSnackbarMessage: (
        message: string,
        severity: Severity,
        timeBeforeClose?: number | null
      ) => void
    }
  | undefined
>(undefined)

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState<Severity>("info")
  const [vanishTimeout, setVanishTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const showSnackbarMessage = (
    message: string,
    severity: Severity,
    timeBeforeClose: number | null = 6000
  ) => {
    setIsOpen(true)
    setMessage(message)
    setSeverity(severity)
    if (vanishTimeout) {
      clearTimeout(vanishTimeout)
    }
    if (timeBeforeClose !== null) {
      setVanishTimeout(
        setTimeout(() => {
          setIsOpen(false)
        }, timeBeforeClose)
      )
    }
  }

  const handleCloseSnackbar = () => {
    setIsOpen(false)
  }

  return (
    <>
      <SnackbarContext.Provider value={{ showSnackbarMessage }}>
        <>
          <MessageSnackbar
            message={message}
            severity={severity}
            isOpen={isOpen}
            handleClose={handleCloseSnackbar}
          />
          {children}
        </>
      </SnackbarContext.Provider>
    </>
  )
}

export const useMessageSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider")
  }
  return context
}
