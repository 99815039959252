"use client"

import React, { ReactNode } from "react"

import CssBaseline from "@mui/material/CssBaseline"
import NextAppDirEmotionCacheProvider from "./emotion-cache"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"

interface ThemeRegistryProps {
  children: ReactNode
}

export default function ThemeRegistry({
  children,
}: ThemeRegistryProps): React.JSX.Element {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  )
}
