"use client"

import _ from "lodash"
import { useSearchParams, usePathname } from "next/navigation"
import { useRouter } from "next/navigation"
import React, { createContext, useContext, useEffect, useState } from "react"

interface UpdateQueryParamsContextInterface {
  addQueryParams: (params: { [key: string]: string }) => void
  removeQueryParams: (params: string[]) => void
  params: { [key: string]: string }
}

const UpdateQueryParamsContext =
  createContext<UpdateQueryParamsContextInterface>({
    addQueryParams: () => {},
    removeQueryParams: () => {},
    params: {},
  })

export const UpdateQueryParamsContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const searchParams = useSearchParams()!
  const router = useRouter()
  const pathname = usePathname()

  const [queryParams, setQueryParams] = useState<{
    [key: string]: string
  }>(Object.fromEntries(searchParams))

  // State to store whether we have navigated to a page with a different path
  // This way we won't run the following useEffect on an initial page load or
  // refresh
  const [navigated, setNavigated] = useState(false)
  useEffect(() => {
    if (navigated) {
      // If we're changing pages, drop all the query params except
      // group, from-date, to-date, and date
      setQueryParams((queryParams) => {
        const newQueryParams: { [key: string]: any } = {}
        for (const key of ["group", "from-date", "to-date", "date"]) {
          if (queryParams[key] !== undefined) {
            newQueryParams[key] = queryParams[key]
          }
        }
        return newQueryParams
      })
    }
    setNavigated(true)
  }, [pathname])

  useEffect(() => {
    if (!_.isEqual(Object.fromEntries(searchParams), queryParams)) {
      router.push(pathname + "?" + new URLSearchParams(queryParams).toString())
    }
  }, [queryParams, searchParams])

  const addQueryParams = (params: { [key: string]: string }) => {
    setQueryParams((currentState) => {
      return { ...currentState, ...params }
    })
  }

  const removeQueryParams = (keys: string[]) => {
    setQueryParams((currentState) =>
      Object.fromEntries(
        Object.entries(currentState).filter(([key, _]) => !keys.includes(key))
      )
    )
  }

  return (
    <UpdateQueryParamsContext.Provider
      value={{
        addQueryParams,
        removeQueryParams,
        params: queryParams,
      }}>
      {children}
    </UpdateQueryParamsContext.Provider>
  )
}

export const useUpdateQueryParams = () => {
  const context = useContext(UpdateQueryParamsContext)
  if (!context) {
    console.error(
      "useUpdateQueryParams must be used within an UpdateQueryParamsProvider"
    )
  }
  return context
}
