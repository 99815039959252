//react typescript component

export default function Logo({
  width,
  height,
  variant = "MAIN",
}: Readonly<{
  width?: number
  height?: number
  variant?: "MAIN" | "ICON_ONLY" | "SIDEBAR"
}>) {
  let src = "/logo/logo.png"
  if (variant === "SIDEBAR") {
    src = "/logo/sidebar-logo.png"
  } else if (variant == "ICON_ONLY") {
    src = "/logo/logo-icon.png"
  }

  // eslint-disable-next-line @next/next/no-img-element
  return <img src={src} width={width} height={height} alt="logo" />
}
